import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToasterService } from 'src/app/_common/services/toaster-service/toaster.service';
import { AppConstants } from 'src/app/_common/shared/app.constants';
import { mailto } from 'src/app/_common/shared/helper.functions';
import { SiteCoreMessagesConstants } from 'src/app/_common/shared/siteCoreMessages.constants';
import { SitecorePageAlert } from 'src/app/_models/sitecore/sitecore-page-alert.model';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';
import { Userpilot } from 'userpilot';
import { IUserInformation } from 'src/app/_models/userInformation.interface';
import { EmployerAccountService } from '../../_services/employer-account-service/employer-account.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  isHome = false;
  public title: string;
  public message: string;
  public messageType: string;
  public hasAccess = false;
  public isAzBeaconUser = false;
  public isEarlyAzBeaconUser = 'false';
  public titleInfomationMessage: string;
  public informationMessage: string;
  public messageInformationType: string;
  public emailContact: string;
  public subjectEmail: string;
  public unsubscribe$ = new Subject<void>();
  public accountName = '';
  public accountStatus = '';
  private _subscription: Subscription;

  constructor(
    public toasterService: ToasterService,
    private router: Router,
    public sitecoreContentService: SitecoreContentService,
    public employerAccountService: EmployerAccountService
  ) { }

 public ngOnInit(): void {
    this.getCurrentRole();
    this.employerAccountService.parentAccountName$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (name: string) => {
        this.accountName = name;
      }
    );
    this.employerAccountService.accountStatus$?.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (accountStatus: string) => {
        this.accountStatus = accountStatus;
      }
    );
    this.addEmployerGroupAndUserInfoToUserPilot();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isHome = this.router.url === '/home' ? true : false;
        if (event.urlAfterRedirects === '/home' ) {
          if (this.hasAccess) {
            this.showToasterMessage();
          }
          if (!this.isAzBeaconUser || this.isEarlyAzBeaconUser === 'false') {
            this.showToasterMessageInfo();
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.toasterService.hideToasterMessage();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private showToasterMessage(): void {
    this.toasterService.pushToasterMessageDashboard({
      title: this.title,
      message: this.message,
      icon: this.messageType,
      bodyClass: { 'card-body-information': true, 'container-margin-homepage': false },
      showCloseButton: false,
      shouldScrollToToaster: true
    });
  }

  private showToasterMessageInfo(): void {
    this.toasterService.pushToastMessageInfo({
      title: this.titleInfomationMessage,
      message: `${this.informationMessage}
      ${mailto({
              message: {
                address: this.emailContact,
                subject: this.subjectEmail,
                body: ''
              },
              tag: {
                innerHtml:  this.emailContact
              }
            })}.`,
      icon: this.messageInformationType,
      bodyClass: { 'card-body-information': true, 'container-margin-homepage': false },
      showCloseButton: true,
      shouldScrollToToaster: true
    });
  }

  public getSiteCoreMessages(): void {
    this.sitecoreContentService.getSiteCoreMessages(SiteCoreMessagesConstants.EMPLOYER_HOME_PAGE_MESSAGE)
      .subscribe(
        (sitecoreItem: SitecorePageAlert) => {
          this.title = sitecoreItem.alertTitle;
          this.message = sitecoreItem.message;
          this.messageType = sitecoreItem.type;
          this.showToasterMessage();
        },
        (error: any) => {
          this.toasterService.hideToasterMessage();
        }
      );
  }

  public getSiteCoreInformationMessage(): void {
    this.sitecoreContentService.getSiteCoreMessages(SiteCoreMessagesConstants.EMPLOYER_HOME_PAGE_INFORMATION_MESSAGE)
      .subscribe(
        (sitecoreItem: SitecorePageAlert) => {
          const messageContent = sitecoreItem.message.split('|');
          this.titleInfomationMessage = sitecoreItem.alertTitle;
          this.informationMessage =  messageContent[0];
          this.messageInformationType = sitecoreItem.type;
          this.emailContact = messageContent[1];
          this.subjectEmail = messageContent[2];
          this.showToasterMessageInfo();
        },
        (error: any) => {
          this.toasterService.hideToasterMessage();
        }
      );
  }

  private getCurrentRole(): void {
    const accessAZBeacon = this.getLocalStorageInfo(AppConstants.EARLY_ACCESS_AZBEACON_KEY);
    const accessHRP = this.getLocalStorageInfo(AppConstants.EARLY_ACCESS_HRP_KEY);
    this.isAzBeaconUser = this.getLocalStorageInfo(AppConstants.AZBEACON_COOKIE_KEY);
    this.isEarlyAzBeaconUser = accessAZBeacon;
    this.hasAccess = accessAZBeacon === 'true' || accessHRP === 'true';
    if (this.hasAccess) {
      this.getSiteCoreMessages();
    }
    if (!this.isAzBeaconUser || this.isEarlyAzBeaconUser === 'false') {
      this.getSiteCoreInformationMessage();
    }
  }

  getLocalStorageInfo(infoKey: string): any {
    const storage = localStorage.getItem(infoKey);
    if (storage) {
      return JSON.parse(storage);
    }
  }

  private getUserInfoLocalStorage(): IUserInformation {
    return (JSON.parse(localStorage.getItem(AppConstants.USER_INFO_DETAILS)) || {}) as IUserInformation;
  }
  private addEmployerGroupAndUserInfoToUserPilot(): void {
      const userInfo = this.getUserInfoLocalStorage();
          Userpilot.identify(
            userInfo?.preferred_username,
            {
              name: userInfo?.given_name + ' ' + userInfo?.family_name, // Adds User Profile Name
              company: { // Needed for Company Searches in UserPilot
                id: userInfo?.accounts, // Required, used to identify the company
                name: this.accountName,
                groupStatus: this.accountStatus
              },
              // userpilot displays in alpha-order
              groupName: this.accountName,
              groupNumber: userInfo?.accounts,
              groupStatus: this.accountStatus,
              userFirstName: userInfo?.given_name,
              userLastName: userInfo?.family_name,
              userRole: userInfo?.employerGroups
            });
  }
}
