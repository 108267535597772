import { Injectable, OnDestroy } from '@angular/core';
import { IAccount, IAccounts, IAccountSummary } from 'src/app/_models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { EmployerAccountApiService } from './employer-account-api.service';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployerAccountService implements OnDestroy {
  private readonly SIX: number = 6;
  accounts: IAccounts = { userFirstName: '', userLastName: '', accounts: [] };
  public parentAccountName$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public accounts$: BehaviorSubject<IAccounts> = new BehaviorSubject<IAccounts>(this.accounts);
  public subAccounts$: BehaviorSubject<IAccounts> = new BehaviorSubject<IAccounts>(null);
  public accountStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private subscriptions: Subscription[] = [];

  constructor(private employerAccountApiService: EmployerAccountApiService) { }

  public setAccountSummary(firstName: string, accountId: string): Observable<IAccountSummary> {
    return this.employerAccountApiService.getAccountSummary(accountId).pipe(
      filter(data => !!data ),
      tap((summaryData: IAccountSummary) => {
        if (summaryData) {
          this.parentAccountName$.next(summaryData?.accountName);
          this.accountStatus$.next(summaryData?.accountStatus);
          this.setAccountDetails(firstName, summaryData);
          this.setSubAccountSummary(firstName);
        }
      })
    );
  }

  public getOktaUserSubAccountSummary(): Observable<IAccountSummary> {
    return this.employerAccountApiService.oktaUserSubAccountSummary$;
  }

  public setSubAccountSummary(firstName: string): void {
    this.subscriptions.push(this.employerAccountApiService.oktaUserSubAccountSummary$.subscribe(
      (subAccountSummary: IAccountSummary) => {
        const subAccountsAsIAccountArray: IAccount[] = this.convertToAccounts(subAccountSummary);
        const result: IAccounts = {
          userFirstName: firstName,
          userLastName: '',
          accounts: subAccountsAsIAccountArray
        };
        this.subAccounts$.next(result);
      }
    ));
  }

  public setAccountDetails(firstName: string, accountSummary: IAccountSummary): void {
    const convertedAccounts: IAccount[] = this.convertToAccounts(accountSummary);

    const result: IAccounts = {
      userFirstName: firstName,
      userLastName: '',
      accounts: convertedAccounts
    };
    this.accounts = result;
    this.accounts$.next(this.accounts);
  }

  private convertToAccounts(accountSummary: IAccountSummary): IAccount[] {
    const result: IAccount[] = [];
    let hasMultiTopAccounts = false;
    let charsCountToRemove = 0;

    if (accountSummary?.subAccounts.length > 0) {
      hasMultiTopAccounts = this.checkIf_MultipleTopAccounts(accountSummary);
      if (hasMultiTopAccounts) {
        charsCountToRemove = (accountSummary.accountId.length) + 1; // plus dash character
      }
    }
    if (hasMultiTopAccounts) {
      accountSummary.subAccounts.forEach((subAccnt) => {
        const topAccountId = subAccnt.accountId.slice(charsCountToRemove);
        const subAccounts = subAccnt?.subAccounts != null && subAccnt.subAccounts.length > 0 ? subAccnt.subAccounts : [];

        const topAccount = this.convertSingleAccount(subAccounts, topAccountId, charsCountToRemove);
        result.push(topAccount);
      });
    } else {
      const topAccountId = accountSummary.accountId;
      const subAccounts = accountSummary.subAccounts != null && accountSummary.subAccounts.length > 0 ? accountSummary.subAccounts : [];
      const topAccount = this.convertSingleAccount(subAccounts, topAccountId, charsCountToRemove);
      result.push(topAccount);
    }
    return result;
  }

  private convertSingleAccount(subAccounts: IAccountSummary[], accountId: string, charsCountToRemove: number): IAccount {
    const topAccount: IAccount = {
      account: accountId,
      subAccounts: []
    };
    if (subAccounts.length > 0) {
      subAccounts.forEach((subAccnt) => {
        topAccount.subAccounts.push(subAccnt);
      });
    }
    return topAccount;
  }

  private checkIf_MultipleTopAccounts(accountSummary: IAccountSummary): boolean {
    const accountIdStringArray: string[] = accountSummary.subAccounts[0].accountId.split('-');
    const hasMultipleTopAccounts = accountIdStringArray
      && accountIdStringArray.length > 1
      && accountIdStringArray[1].trim().length === this.SIX;
    return hasMultipleTopAccounts;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
}
}
