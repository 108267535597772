import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUserAccountInfo, IAccounts, IAccount } from '../../_models';
import { EmployerAccountService } from '../../_services/employer-account-service/employer-account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserWelcomeService } from './user-welcome.service';
import { ModalService } from 'src/app/_common/services/modal-service/modal.service';
import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {
  private readonly THIRTEEN: number = 13;
  accountInfo: IUserAccountInfo = { userFirstName: '', accountNumber: '' };
  accountsWithSubAccounts: IAccount[];
  accountsToDisplay: string;
  accountsExtension = '...Show All';
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private employerAccountService: EmployerAccountService,
    private userWelcomeService: UserWelcomeService,
    private modalService: ModalService,
    private sitecoreContentService: SitecoreContentService
  ) { }

  ngOnInit(): void {
    this.employerAccountService.accounts$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (accounts: IAccounts) => {
        if (accounts && accounts?.accounts !== this.accountsWithSubAccounts) {
          this.accountInfo.userFirstName = accounts?.userFirstName;
          this.setUserAccountInformation(accounts?.accounts);
        }
      }
    );
    this.getSiteCoreToggle();
  }

  getSiteCoreToggle(): void {
    this.sitecoreContentService.getSiteCoreToggle('EmployerPortal_Modal_Home_Toggle').subscribe(toggle => {
      if (toggle) {
        if (this.userWelcomeService.loginCount < this.userWelcomeService.maxCount) {
          this.userWelcomeService.incrementWelcomeCount();
          this.modalService.createDynamicOptionsModal({
            componentType: WelcomeModalComponent,
            buttonContainer: {
              buttons: [this.modalService.createDefaultPrimaryButton('Got it')]
            }
          }, {maxWidth: 580});
        }
      }
    });
  }

  setUserAccountInformation(accounts: IAccount[]) {
    this.accountsWithSubAccounts = accounts;
    const userAccounts: IAccount[] = accounts;
    const accountsStringArray: string[] = [];
    userAccounts.forEach(a => accountsStringArray.push(a.account));
    this.accountInfo.accountNumber = accountsStringArray.join(', ');
    this.accountsToDisplay = this.accountInfo?.accountNumber.length < this.THIRTEEN
      ? this.accountInfo?.accountNumber
      : this.accountInfo?.accountNumber.substring(0, this.THIRTEEN);
  }

  toggleAccountsView() {
    this.accountsExtension = this.accountsExtension === '...Show All' ? 'Show Less' : '...Show All';
    this.accountsToDisplay = this.accountsToDisplay === this.accountInfo?.accountNumber
      ? this.accountInfo?.accountNumber.substring(0, this.THIRTEEN)
      : this.accountInfo?.accountNumber;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
